@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Titillium+Web:wght@300;400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @TODO FOR TEST PURPOSE, DELETE LATER*/
:root {
  --primary-bg-color: #c4492c;
  --primary-hover-bg-color: #a33419;
  --primary-active-bg-color: #842a15;
  --secondary-bg-color: #006534;
  --secondary-hover-bg-color: #003d20;
  --secondary-active-bg-color: #002412;
  --background-color: #05231C;
  --links-color: #2c5a97;
  --primary-text-color: #c4492c;
  --secondary-text-color: #4f4f4f;
  --tertiary-text-color: #00231B;
  --white: #fff;
  --black: #000;
  --red: #c4492c;
  --gold-200: #f1e9da;
  --gold-800: #73592b;
  --input-bg-base: #f2f2f2;
  --input-bg-hover: #e6e6e6;
  --input-border-error: #D81F1F;
}

@media only screen and (min-width: 769px) {
  .only_mobile {
    display: none !important;
  }

  /* @XXX Put here because id not detect on module */
  div#no_refund {
    max-width: 300px;
  }
}

@media only screen and (max-width: 768px) {
  .only_desktop {
    display: none !important;
  }

  /* @XXX Put here because id not detect on module */
  div#no_refund {
    max-width: 200px;
  }
}

:root {
    --primary-bg-color: #007031;
    --secondary-bg-color: transparent;
    --background-color: #fff;    
    --primary-text-color: #231F20;
    --primary-hover-bg-color : #00471F;
    --primary-active-bg-color : #007031;
    --secondary-text-color: #3A3A39;
    --tertiary-text-color: #676765; 
    --links-color: #3182CE;
    --border-color:#E6E7E9;
    --border-color-hover: #CBCCCD;
    --border-color-focus: #008A3D;
}  

.modal_container__1ezZk {
  z-index: 9999;
}

h1 {
  margin: 0;
}

.modal_overlay_visible__MiSgZ {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--black);
  opacity: 0.3;
}

.modal_overlay_hidden__3tKvQ {
  display: none;
}

.modal_title__11zz- {
  color: var(--primary-text-color);
  margin-top: 8px;
  margin-bottom: 24px;
}

@-webkit-keyframes modal_showModalMobile__UpnFI {
  from {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes modal_showModalMobile__UpnFI {
  from {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes modal_hideModalMobile__FGs-7 {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

@keyframes modal_hideModalMobile__FGs-7 {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}

@-webkit-keyframes modal_showModal__1SJBM {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modal_showModal__1SJBM {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes modal_hideModal__fQpP5 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes modal_hideModal__fQpP5 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes modal_showModalMobile__UpnFI {
  from {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes modal_hideModalMobile__FGs-7 {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(150%);
            transform: translateY(150%);
  }
}

.modal_modal__5Ffqc {
  position: fixed;
  background-color: var(--white);
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0px 12px 44px rgba(0, 0, 0, 0.11);
}

.modal_modal_visible__QnAyT {
  display: inline !important;
  display: initial !important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 450px;
  -webkit-animation: modal_showModal__1SJBM 300ms;
          animation: modal_showModal__1SJBM 300ms;
}

.modal_modal_hidden__qmGNq {
  display: none;
}

.modal_modal_hidden_active__108pN {
  display: inline !important;
  display: initial !important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  max-width: 450px;
  -webkit-animation: modal_hideModal__fQpP5 300ms;
          animation: modal_hideModal__fQpP5 300ms;
}

.modal_close_button__2KNoY {
  display: none;
}

@media only screen and (max-width: 768px) {
  .modal_modal__5Ffqc {
    border-radius: unset;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    max-width: none;
    -webkit-transform: none;
            transform: none;
    top: auto;
    top: initial;
    padding: 16px;
    box-shadow: none;
  }

  .modal_modal_visible__QnAyT {
    display: inline !important;
    display: initial !important;
    right: 0;
    left: 0;
    bottom: 0;
    -webkit-animation: modal_showModalMobile__UpnFI 300ms;
            animation: modal_showModalMobile__UpnFI 300ms;
  }

  .modal_modal_hidden_active__108pN {
    display: inline !important;
    display: initial !important;
    right: 0;
    left: 0;
    bottom: 0;
    -webkit-animation: modal_hideModalMobile__FGs-7 300ms;
            animation: modal_hideModalMobile__FGs-7 300ms;
  }

  .modal_close_button__2KNoY {
    display: inline;
    display: initial;
    position: absolute;
    top: 0;
    right: 16px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    outline: none;
  }
}

.modal_modal__5Ffqc {
    border-radius: unset;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 24px 16px 16px;
    box-shadow: none;
}

.modal_title__11zz- {
    margin-top: 0;
    margin-bottom: 24px;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 400;
    color: var(--secondary-text-color);
}

.modal_buy_card__11NT5 {
    font-size: 14px;
    line-height: 17px;
    color: var(--links-color);
}

@media only screen and (min-width: 768px) {
    .modal_modal__5Ffqc {
        padding:40px;
        border-radius:6px;
    }
}
.snackBar_container__qFz_n {
  z-index: 9999;
}

.snackBar_icon__3_fss {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 13px;
}

.snackBar_content__rtkOj {
  margin: 0;
  color: var(--tertiary-text-color);
  font-size: 14px;
}

@-webkit-keyframes snackBar_showSnackBarMobile__2Ka8M {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes snackBar_showSnackBarMobile__2Ka8M {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes snackBar_hideSnackBarMobile__QM-AM {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@keyframes snackBar_hideSnackBarMobile__QM-AM {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@-webkit-keyframes snackBar_showSnackBar__THv-2 {
  from {
    -webkit-transform: translateX(calc(100% + 24px));
            transform: translateX(calc(100% + 24px));
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes snackBar_showSnackBar__THv-2 {
  from {
    -webkit-transform: translateX(calc(100% + 24px));
            transform: translateX(calc(100% + 24px));
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes snackBar_hideSnackBar__3rEks {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(calc(100% + 24px));
            transform: translateX(calc(100% + 24px));
  }
}

@keyframes snackBar_hideSnackBar__3rEks {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(calc(100% + 24px));
            transform: translateX(calc(100% + 24px));
  }
}

.snackBar_snack_bar__2_nKY {
  position: fixed;
  top: 24px;
  right: 24px;
  align-items: center;
  flex-direction: row;
  background-color: var(--white);
  border-radius: 6px;
  padding: 17px 24px 17px 24px;
  max-width: 400px;
  -webkit-animation-timing-function: cubic-bezier(0.345, 0.355, 0.000, 1.000);
          animation-timing-function: cubic-bezier(0.345, 0.355, 0.000, 1.000);
  -webkit-animation-duration: 300ms;
          animation-duration: 300ms;
}

.snackBar_snack_bar_visible__1AbKS {
  display: flex !important;
  -webkit-animation-name: snackBar_showSnackBar__THv-2;
          animation-name: snackBar_showSnackBar__THv-2;
}

.snackBar_snack_bar_hidden__3asHr {
  display: none;
  -webkit-animation-name: snackBar_hideSnackBar__3rEks;
          animation-name: snackBar_hideSnackBar__3rEks;
}

.snackBar_snack_bar_hidden_active__3CbfK {
  display: flex !important;
  -webkit-animation-name: snackBar_hideSnackBar__3rEks;
          animation-name: snackBar_hideSnackBar__3rEks;
}

@media only screen and (max-width: 768px) {
  .snackBar_snack_bar__2_nKY {
    top: 0;
    right: 0;
    left: 0;
    background-color: rgb(26,26,26, 0.9);
    max-width: none;
    height: 32px;
    border-radius: 0;
    justify-content: center;
  }

  .snackBar_snack_bar_visible__1AbKS {
    display: flex !important;
    -webkit-animation-name: snackBar_showSnackBarMobile__2Ka8M;
            animation-name: snackBar_showSnackBarMobile__2Ka8M;
  }

  .snackBar_snack_bar_hidden_active__3CbfK {
    display: flex !important;
    -webkit-animation-name: snackBar_hideSnackBarMobile__QM-AM;
            animation-name: snackBar_hideSnackBarMobile__QM-AM;
  }

  .snackBar_snack_bar_hidden__3asHr {
    display: none;
    -webkit-animation-name: snackBar_hideSnackBarMobile__QM-AM;
            animation-name: snackBar_hideSnackBarMobile__QM-AM;
  }

  .snackBar_content__rtkOj {
    color: var(--white);
  }
}

.login_main__3H61c {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login_leftContent__EdMPo {
  background-color: white;
  padding: 24px 16px 40px 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.login_titleLeft__fXFfs {
  margin-bottom: 24px;
}

.login_mainTitleLeft__1BGKu {
  margin-bottom: 32px;
}

.login_mainTitleLeft__1BGKu > p {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: var(--primary-bg-color);
  margin: 0;
}

.login_explanationLeft__2IYUo {
  margin-top: 24px;
  margin-bottom: 32px;
  display: none;
}

.login_titleLeft__fXFfs {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: var(--secondary-bg-color);
  margin: 0;
}

.login_textLeft__JcU9Z {
  font-size: 15px;
  line-height: 20px;
  margin-top: 4px;
  margin-bottom: 0;
  color: var( --secondary-text-color)
}

.login_input__2s4Tl {
  margin-bottom: 24px;
}

.login_inputPassword__9pM0h {
  margin-bottom: 32px;
}

.login_connectionContainer__1Q5CV {
  display: flex;
  flex-direction: column;
}

.login_forgotPassword__3YFBh {
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: var(--links-color);
  cursor: pointer;
  margin-top: 17px;
}

.login_rightContent__1oXxr {
  background-color: white;
  padding: 24px 16px 56px 16px;
}

.login_mainTitleRight__2vrTy {
  margin-bottom: 32px;
}

.login_titleRight__2cGug > span {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #006534;
}

.login_list__34E9A {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.login_subtitle__23uVa {
  margin-top: 24px;
  margin-bottom: 20px;
}

.login_subtitle__23uVa > span {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #00231B;
}

.login_list__34E9A:not(:last-child) {
  margin-bottom: 20px;
}

.login_listText__3Pdxu {
  font-size: 14px;
  line-height: 20px;
  color: var(--tertiary-text-color)
}

.login_subscribeButton__3V84q {
  margin-top: 40px;
}

@media(min-width: 768px) {
  .login_main__3H61c {
    flex-direction: row;
  }

  .login_leftContent__EdMPo {
    max-width: 408px;
    border-top-left-radius: 50px;
    padding: 40px 56px 34px 56px;
    box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.2);
  }

  .login_explanationLeft__2IYUo {
    display: block;
  }

  .login_connectionContainer__1Q5CV {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .login_connectButtonContainer__362t8 {
    min-width: 194px;
  }

  .login_rightContent__1oXxr {
    width: 246px;
    padding: 40px 40px 74px 40px;
  }

  .login_mainTitleRight__2vrTy {
    margin-bottom: 24px;
  }

  .login_subscribeButton__3V84q {
    margin-top: 55px;
  }
}

.login_leftContent__EdMPo {
    background-color: none;
    padding: 25px 16px 40px 16px;
    box-shadow: none;
    z-index: 2;
    margin:0 auto;
}

.login_mainTitleLeft__1BGKu {
    margin-bottom: 16px;
}

.login_mainTitleLeft__1BGKu > p {
    font-family: 'Titillium Web', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 37px;
    color: var(--primary-text-color);
    margin: 0;
}

.login_explanationLeft__2IYUo {
    display: block;
    margin:0 0 32px;
}

.login_connectionContainer__1Q5CV {
    padding-bottom:40px;
    border-bottom:1px solid #E6E7E9;
}

.login_forgotPassword__3YFBh {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
    color: var(--links-color);
    cursor: pointer;
    margin-top: 24px;
    display: none; /* @TODO: Show modal when LCG V2 website is online */
}

.login_connectButtonContainer__362t8 {
    min-width: 194px;
}

.login_connectButtonContainer__362t8 > button {
    width:100%;
}

.login_rightContent__1oXxr {
    background-color: white;
    padding: 0 16px 68px 16px;
    width:auto;
    margin:0 auto;
}

.login_titleRight__2cGug {
    margin-bottom: 32px;
}

.login_titleRight__2cGug > span {
    font-family: 'Titillium Web', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 37px;
    color: var(--primary-text-color);
    margin: 0;
}

.login_subtitle__23uVa {
    margin-top: 0;
    margin-bottom: 16px;
}

.login_subtitle__23uVa > span {
    font-weight: 500;
    font-size: 15px;
    line-height: 160%;
    color: var(--primary-text-color);
}

.login_textLeft__JcU9Z {
    font-size: 15px;
    line-height: 160%;
}

.login_subscribeButton__3V84q {
    margin-top: 32px;
}

.login_subscribeButton__3V84q  button {
    width:100%;
}

@media only screen and (min-width: 500px) {
    .login_connectButtonContainer__362t8 > button {
        width:auto;
    }

    .login_subscribeButton__3V84q  button {
        width:auto;
    }

    .login_connectionContainer__1Q5CV {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items:center;
    }

    .login_forgotPassword__3YFBh {
        margin-top:0;
    }
}

@media only screen and (min-width: 768px) {
    .login_leftContent__EdMPo {
        margin: 0;
        padding: 0 32px;
        max-width: 519px;
        border-right:1px solid #E6E7E9;
    }

    .login_mainTitleLeft__1BGKu > p {
        font-size: 32px;
        line-height: 49px;
    }

    .login_connectionContainer__1Q5CV {
        border-bottom:none;
        padding: 0;
    }

    .login_rightContent__1oXxr {
        margin: 0;
        padding: 0 32px;
        max-width: 374px;
    }
}


.error_main__3WwDD {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 7px;
}

.error_message__cuRJU {
  margin-left: 10px;
  font-size: 12px;
  color: var(--input-border-error);
}

.error_main__3WwDD {
    justify-content: flex-start;
}
.input_main__1yBD3 {
  display: flex;
  position: relative;
  flex-direction: column;
}

.input_label__1-p4q {
  text-align: left;
  font-weight:bold;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
}

.input_required__1VXQg:after {
  content:'*';
}

.input_input__zY48e {
  border: none;
  outline: none;
  height: 46px;
  padding: 0 24px 0 24px;
  background-color: var(--input-bg-base);
  border-radius: 100px;
}

.input_input__zY48e:focus {
  background-color: var(--input-bg-hover)
}

.input_input__zY48e:hover {
  background-color: var(--input-bg-hover)
}

.input_error__376GK {
  border: 1px solid var(--input-border-error);
  height: 44px;
  padding: 0 23px 0 23px;
}

.input_label__1-p4q {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: var(--secondary-text-color);
}

.input_input__zY48e {
    border: none;
    outline: none;
    height: 48px;
    padding: 0 16px;
    border: 1px solid var(--border-color);
    background-color: var(--white);
    border-radius: 6px;
    font-size: 14px;
    line-height: 17px;
}
  
.input_input__zY48e::-webkit-input-placeholder {
    color:#929290;
}
  
.input_input__zY48e:-ms-input-placeholder {
    color:#929290;
}
  
.input_input__zY48e::-ms-input-placeholder {
    color:#929290;
}
  
.input_input__zY48e::placeholder {
    color:#929290;
}
  
.input_input__zY48e:focus {
    border: 1px solid var(--border-color-focus);
    background-color: var(--white);
}
  
.input_input__zY48e:hover {
    border: 1px solid var(--border-color-hover);
    background-color: var(--white);
}
  
.input_error__376GK {
    border: 1px solid var(--input-border-error);
    height: 44px;
    padding: 0 23px 0 23px;
}
  
.loader_main__1zywa {
  display: inline-block;
  position: relative;
  width: 70px;
  height: 30px;
}

.loader_wrapper__1l995 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader_main_center__1huT9 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.loader_bubble__2kU0D {
  position: absolute;
  top: 12px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--white);
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader_primary__XFL_f {
  background: var(--primary-bg-color);
}

.loader_firstBubble__2dj1h {
  left: 8px;
  -webkit-animation: loader_firstBubbleAnimation___5kw6 0.6s infinite;
          animation: loader_firstBubbleAnimation___5kw6 0.6s infinite;
}

.loader_secondBubble__8rmIV {
  left: 8px;
  -webkit-animation: loader_secondBubbleAnimation__HuQqi 0.6s infinite;
          animation: loader_secondBubbleAnimation__HuQqi 0.6s infinite;
}

.loader_thirdBubble__3hYUj {
  left: 32px;
  -webkit-animation: loader_secondBubbleAnimation__HuQqi 0.6s infinite;
          animation: loader_secondBubbleAnimation__HuQqi 0.6s infinite;
}

.loader_fourthBubble__jZmAm {
  left: 56px;
  -webkit-animation: loader_thirdBubbleAnimation__1pUiZ 0.6s infinite;
          animation: loader_thirdBubbleAnimation__1pUiZ 0.6s infinite;
}

@-webkit-keyframes loader_firstBubbleAnimation___5kw6 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes loader_firstBubbleAnimation___5kw6 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes loader_secondBubbleAnimation__HuQqi {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}

@keyframes loader_secondBubbleAnimation__HuQqi {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}

@-webkit-keyframes loader_thirdBubbleAnimation__1pUiZ {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes loader_thirdBubbleAnimation__1pUiZ {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

.button_main__1QuE9 {
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  height: 45px;
  line-height: 15px;
  letter-spacing: 0.2em;
  outline: none;
  text-transform: uppercase;
  width: 100%;
}

.button_primary__3qZbv {
  background-color: var(--primary-bg-color);
}

.button_primary__3qZbv:hover {
  background-color: var(--primary-hover-bg-color);
}

.button_primary__3qZbv:active {
  background-color: var(--primary-active-bg-color);
}

.button_secondary___uoIx {
  background-color: var(--secondary-bg-color);
}

.button_secondary___uoIx:hover {
  background-color: var(--secondary-hover-bg-color);
}

.button_secondary___uoIx:active {
  background-color: var(--secondary-active-bg-color);
}

.button_disabled__3eNpo {
  cursor: not-allowed;
  opacity: 0.6;
}

.button_disabled__3eNpo.button_primary__3qZbv:hover {
  background-color: var(--primary-bg-color);
}

.button_disabled__3eNpo.button_secondary___uoIx:hover {
  background-color: var(--secondary-bg-color);
}
.button_main__1QuE9 {
  outline: none;
  border: none;
  border-radius: 6px;
  width: auto;
  height: 48px;
  padding : 0 32px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  line-height: 15px;
  letter-spacing: 0.1em;
  color: #FFFFFF;
  text-transform: uppercase;
}

.button_primary__3qZbv {
  background-color: var(--primary-bg-color);
}

.button_primary__3qZbv:hover {
  background-color: var(--primary-hover-bg-color);
}

.button_primary__3qZbv:active {
  background-color: var(--primary-active-bg-color);
}

.button_secondary___uoIx {
  background-color: var(--white);
  border: 2px solid #E6E7E9;
  box-sizing: border-box;
  border-radius: 6px;
  color: var(--secondary-text-color); 
}

.button_tertiary__2RbU2 {
  background-color:transparent;
  height:auto;
  padding:0;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  text-align: right;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--primary-bg-color);
} 

.button_secondary___uoIx:hover {
  background-color: var(--white);
  border: 2px solid #CBCCCD;
}

.button_secondary___uoIx:active {
  background-color: var(--white);
  border: 2px solid #E6E7E9;
}
  
.button_disabled__3eNpo {
  cursor: not-allowed;
  opacity: 0.6;
}
  
.button_disabled__3eNpo.button_primary__3qZbv:hover {
  background-color: var(--primary-bg-color);
}
  
.button_disabled__3eNpo.button_secondary___uoIx:hover {
  background-color: var(--secondary-bg-color);
}
.forgetPassword_content__2qRH3 {
  color: var(--secondary-text-color);
  font-size: 15px;
}

/* Check icon for login right part */
.customCheck {
  display: flex;
}
.customCheck > .check {
  position: relative;
  right: 17px
}

.customCheck > .check {
  position: relative;
  right: inherit;
  margin-right:12px;
}

.register_main__2xkJb {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.register_content__quGtv {
  background-color: white;
  width: 526px;
  padding: 40px 56px 88px 56px;
  border-top-left-radius: 50px;
  box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.2);
}

.register_title__2jBRD > p {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: var(--primary-bg-color);
  margin-bottom: 24px;
}

.register_inputWrapper__2tb-w {
  flex: 1 1;
  margin-bottom: 32px;
  margin-right: 7px;
}

.register_inputSecondWrapper__1y2R7 {
  flex: 1 1;
  margin-bottom: 32px;
  margin-left: 7px;
}

.register_row__27Ft2 {
  display: flex;
  flex-direction: row;
}

.register_selectWrapper__3ESad {
  margin-bottom: 32px;
  width: calc(50% - 11px);
}

.register_registerContainer__3-Xiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.register_submitButton__3-Xfa {
  min-width: 219px;
}

.register_alreadyClient__20Pk8 {
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: var(--links-color);
  cursor: pointer;
}

@media(max-width: 768px) {
  .register_content__quGtv {
    border-top-left-radius: 0;
    padding: 40px;
    width: 100%;
  }

  .register_inputWrapper__2tb-w {
    margin-right: 0;
  }

  .register_inputSecondWrapper__1y2R7 {
    margin-left: 0;
  }

  .register_registerContainer__3-Xiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .register_alreadyClient__20Pk8 {
    margin-top: 35px;
  }

  .register_submitButton__3-Xfa {
    min-width: 100%;
  }

  .register_row__27Ft2 {
    flex-direction: column;
  }

  .register_selectWrapper__3ESad {
    flex: 1 1;
    width: inherit;
  }
}

.register_item__1LRrK {
  width: 100%;
  margin-bottom: 24px;
}

.register_selectWrapperFullWidth__isN09 {
  margin-bottom: 32px;
  width: 100%;
}

.register_inputDescription__3ICC5 {
  color: var(--secondary-text-color);
  font-size: 11px;
  line-height: 18px;
  margin-top: 10px;
}

.register_content__quGtv {
    background-color: white;
    width: 100%;
    max-width:628px;
    padding: 24px 16px 37px;
    border-radius:0px;
    box-shadow: none;
}

.register_title__2jBRD > p {
    margin: 0 0 24px;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 37px;
    color: var(--primary-text-color);
}

.register_selectWrapper__3ESad {
    margin-bottom: 24px;
}

.register_inputWrapper__2tb-w {
    margin-right: 0;
}

.register_inputSecondWrapper__1y2R7 {
    margin-left: 0;
}

.register_submitButton__3-Xfa > button {
    width:100%;
}
.register_alreadyClient__20Pk8 {
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
}

.register_alreadyClient__20Pk8 a {
    font-size: 14px;
    line-height: 17px;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
    color: var(--links-color);
    cursor: pointer;
}

.register_registerContainer__3-Xiv {
    margin-top:8px;
}

@media(min-width: 768px) {
    .register_title__2jBRD > p {
        margin: 0 0 32px;
        font-size: 32px;
        line-height: 49px;
    }
    .register_content__quGtv {
        padding: 0 16px 142px;
    }

    .register_inputWrapper__2tb-w {
        margin-right: 12px;
    }

    .register_inputSecondWrapper__1y2R7 {
        margin-left: 12px;
    }
}
.select_main__1r7qg {
  display: flex;
  position: relative;
  flex-direction: column;
}

.select_label__227x7 {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
}

.select_required__1c8s2:after {
  content:'*';
}

.select_select__r4iBF {
  border: none;
  outline: none;
  height: 46px;
  padding: 0 24px 0 24px;
  background-color: var(--input-bg-base);
  border-radius: 100px;
  width: 100%;
  display: flex;
  align-items: center;
}

.select_error__2zIaE {
  border: 1px solid var(--input-border-error);
  height: 44px;
  padding: 0 23px 0 23px;
}

.select_label__227x7 {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: var(--secondary-text-color);
}

.select_select__r4iBF {
    border: none;
    outline: none;
    height: 48px;
    padding: 0;
    border: 1px solid #E6E7E9;
    background-color: var(--white);
    border-radius: 6px;
    font-size: 14px;
    line-height: 17px;
}
.checkBox_main__3nMB5 {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.checkBox_main__3nMB5 input {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.checkBox_main__3nMB5 input:checked ~ .checkBox_checkbox__IvOpB {
  background-color: #FAFAFA;
  border-color: var(--primary-bg-color);
}

.checkBox_main__3nMB5 input:checked ~ .checkBox_checkbox__IvOpB:after {
  display: block;
}

.checkBox_main__3nMB5:hover .checkBox_chekbox__2LEqt {
  border-color: #CBCCCD;
}

.checkBox_label__1LXwP {
  margin-left: 2px;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary-bg-color);
}

.checkBox_label__1LXwP > a {
  position: relative;
  z-index: 1;
}

.checkBox_checkbox__IvOpB {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  min-width: 22px;
  margin-right: 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.checkBox_checkbox__IvOpB:after {
  content: "";
  position: absolute;
  display: none;
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid var(--primary-bg-color);
  border-width: 0 1.8px 1.8px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.collapsable_main__12faY {
  position: relative;
}

.collapsable_desktop_container__3BtcF {
  height: 100%;
}

.collapsable_container__2s3Rb {
  border-radius: 6px;
  border: 1px solid #CDCDCD;
  overflow: hidden;
  display: block;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.collapsable_button__Lkytv {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.collapsable_button_opened__1ue5e {
  -webkit-transform: translate(-50%, 50%) rotate(180deg);
          transform: translate(-50%, 50%) rotate(180deg);
}

.collapsable_main__12faY {
    position: relative;
}

.collapsable_desktop_container__3BtcF {
    height: 100%;
}

.collapsable_container__2s3Rb {
    border: 1px solid var(--border-color);
    padding: 5px;
    overflow: hidden;
}

.collapsable_button__Lkytv {
    width: 24px;
    height: 24px;
    background-color:var(--primary-bg-color);
    border-radius: 12px;
}

.collapsable_button_opened__1ue5e {
    -webkit-transform: translate(-50%, 50%) rotate(180deg);
            transform: translate(-50%, 50%) rotate(180deg);
}

.session_main__3AoE7 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 600px;
  height: 1px;
}

.session_left_content__zVu1K {
  z-index: 2;
}

.session_limit__2mH5y {
  text-align: left;
  padding: 24px 16px;
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-text-color);
}

.session_limitMsg__32DEd {
  background: #fff6eb;
  font-size: 12px;
  line-height: 15px;
  padding: 12px 16px;
  text-align: center;
  color: #9c3821;
  border-radius: 6px;
  display: block;
}

.session_right_content__1d4BB,
.session_left_content__zVu1K {
  background-color: white;
  box-sizing: border-box;
  padding: 24px 16px;
}

.session_section_container__1V_ie {
  display: flex;
  flex: 1 1;
  width: 100%;
  align-items: center;
  margin-left: 20px;
}

.session_section_container__1V_ie:first-of-type {
  margin-bottom: 10px;
  margin-left: 0px;
}

.session_section_container__1V_ie:last-of-type {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  border-top: solid 1px var(--input-bg-hover);
  padding-top: 20px;
}

.session_information_container__3OGnO {
  flex: 2 1;
  margin-left: 16px;
}

.session_title__3ktM6 {
  margin: 0px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--tertiary-text-color);
}

.session_sub_title__h9Foy {
  margin: 0px;
  font-size: 14px;
  color: #6d7876; /* only used here */
}

.session_price__QDB1p {
  font-size: 19px;
  margin-right: 10px;
  margin-left: 10px;
}

.session_section_label__3MXZ_ {
  font-size: 14px;
  margin-left: 20px;
}

.session_title_right__37ZJM {
  font-weight: bold;
  font-size: 24px;
  color: var(--primary-bg-color);
  margin: 0;
  margin-bottom: 23px;
}

.session_info_right__3cUre {
  font-family: "Titillium Web";
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
}

.session_player__3yUyM {
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px var(--input-bg-hover);
}

.session_player_name__18K0a {
  margin: 0 0 8px;
  font-size: 14px;
}

.session_player_card__jb1gU {
  border-radius: 100px;
  padding: 6px 12px;
  font-size: 8px;
  font-weight: 700;
  line-height: 10px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  background: transparent;
  border: none;
}

.session_player_price__n2vgt {
  color: var(--tertiary-text-color);
  font-size: 19px;
  font-weight: 500;
}

.session_player_price_container__324Ci {
  display: flex;
  align-items: center;
}

.session_player__3yUyM:first-of-type {
  cursor: default !important;
}

.session_player_customize__3KP-I {
  display: flex;
  align-items: center;
  outline: none;
  background: none;
  border: none;
  margin: 0 8px 0 0;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #00471f;
  cursor: pointer;
}

.session_player_customize__3KP-I > svg {
  margin-right: 8px;
}

.session_public_price__thXfA {
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
  margin: 0 8px 0 0;
}

.session_purchase_button__2LZ6G > button {
  margin-top: 53px;
  background: var(--primary-bg-color);
}

@media (min-width: 768px) {
  .session_main__3AoE7 {
    flex-direction: row;
    justify-content: center;
  }

  .session_limit__2mH5y {
    text-align: center;
  }

  .session_left_container__2ZvSd {
    position: relative;
    width: 100%;
    max-width: 300px;
  }

  .session_left_content__zVu1K {
    width: 100%;
    border-top-left-radius: 30px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }

  .session_player_card__jb1gU {
    margin-left: 8px;
  }

  .session_player_name_container__Z_OsL {
    display: flex;
    align-items: center;
  }

  .session_player_name__18K0a {
    margin: 0;
  }

  .session_player_customize__3KP-I {
    margin: 0 24px 0 0;
  }

  .session_player_customize_mobile__BxhAD {
    display: none;
  }

  .session_right_content__1d4BB {
    padding: 32px 40px 40px 40px;
    max-width: 540px;
    width: 600px;
  }

  .session_left_container__2ZvSd,
  .session_right_content__1d4BB {
    position: relative;
    height: 100%;
  }

  .session_price__QDB1p,
  .session_section_container__1V_ie {
    margin: 0;
  }

  .session_purchase_button__2LZ6G {
    position: absolute;
    bottom: 40px;
    right: 40px;
    width: 300px;
  }
}

.session_paiementFail__wiLk1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 16px;
  z-index: 9999;
}

.session_paiementFail_box__vFXuI {
  padding: 24px;
  background-color: #fff;
  width: 100%;
  max-width: 512px;
}

.session_paiementFail_title__2Hxq8 {
  font-weight: 300;
  font-size: 26px;
  line-height: 40px;
  color: #3a3a39;
  margin-bottom: 24px;
  margin-top: 0;
}

.session_paiementFail_text__3wilP {
  font-size: 14px;
  line-height: 160%;
  color: #3a3a39;
}

@media (min-width: 768px) {
  .session_paiementFail_box__vFXuI {
    padding: 40px;
  }
}
.session_golf_icon__3H9bD {
    display:flex;
    align-items:center;
    justify-content:center;
    width:80px;
    height:80px;
    background: #D8EFCD;
    border-radius: 6px;
}

.session_title__3ktM6 {
    margin-bottom:2px;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: var(--secondary-text-color);
}

.session_sub_title__h9Foy {
    margin: 0px;
    font-size: 12px;
    line-height: 15px;
    color: var(--tertiary-text-color);
}

.session_price__QDB1p {
    display: block;
    margin:6px 0 0;
    font-size: 19px;
    font-weight:600;
}

.session_section_container__1V_ie {
    margin-bottom:18px;
}

.session_section_container__1V_ie:first-of-type {
    margin-bottom:16px;
 }

.session_section_container__1V_ie:last-of-type {
    position: relative;
    padding-top:12px;
}

.session_right_content__1d4BB {
    padding:0 16px 24px;
}

.session_title_right__37ZJM{
    margin: 0 0 16px 0;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 37px;
    color: var(--primary-text-color);
}

.session_information__1ueTU {
    margin-bottom:24px;
    padding:16px;
    background: #F1E9DA;
    border-radius: 6px;
}

.session_information__1ueTU p {
    margin:0;
}

.session_information_title__QH7ce {
    margin-bottom:4px;
    font-weight: 600;
    font-size: 13px;
    line-height: 160%;
    color:#4A391C;
}

.session_information_text__Jmnc3{
    font-size: 12px;
    line-height: 160%;
    color: #73592B;
}

.session_section_label__3MXZ_ {
    margin: 0;
    margin-left: 12px;
    font-size: 13px;
    line-height: 16px;
    color:var(--secondary-text-color);
}

.session_player__3yUyM {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom:16px;
    padding: 27px 16px;
    border: solid 1px var(--border-color);
    border-radius: 6px;
}

.session_player__3yUyM:hover {
    border: 1px solid var(--border-color-hover);
    cursor:pointer;
}

.session_player_card__jb1gU {
    color: var(--primary-hover-bg-color);
    background-color: #D8EFCD;
    border:none;
}

.session_player_price__n2vgt {
    color: var(--secondary-text-color);
    margin: 0;
}

.session_player_name__18K0a {
    font-size: 14px;
    line-height: 17px;
}

.session_public_price__thXfA {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    -webkit-text-decoration-line: line-through;
            text-decoration-line: line-through;
    color: #B1B2B3;
}

.session_player_customize__3KP-I > svg {
    display:none;
    margin-right:8px;
}

.session_player_customize__3KP-I > p {
    margin:0;
}

.session_purchase_button__2LZ6G {
    text-align: right;
}

.session_purchase_button__2LZ6G > button {
    margin-top: 32px;
}

.session_add_card__3ufYf {
    padding:24px;
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    border-radius: 6px;
}

.session_add_card_title__1BeYN {
    margin:0 0 24px;
    font-size: 19px;
    line-height: 130%;
    color: var(--secondary-text-color);
}

.session_add_card_submit__2U7K0 {
    margin-top:24px;
    text-align:center;
}

.session_add_card_submit_right__1mtKO {
    margin-top:24px;
    text-align:right;
}

.session_add_card__error__3aoGf {
    background: #FFEDEB;
    padding:16px;
    margin-top:24px;
    border-radius: 6px;
    font-size: 13px;
    line-height: 160%;
    text-align: center;
    color: #9A1604;
}

.session_add_card__success__1Vitu {
    background: #F3FAF0;
    padding:16px;
    margin-top:24px;
    border-radius: 6px;
    font-size: 13px;
    line-height: 160%;
    text-align: center;
    color: var(--primary-hover-bg-color);
}

@media(min-width: 768px) {
    .session_main__3AoE7 {
        padding:0 16px;
        min-height: auto;
        height:auto;
    }

    .session_left_container__2ZvSd {
        width: 100%;
        max-width:300px;
        margin-right:32px;
    }

    .session_left_content__zVu1K {
        width: 100%;
        border: 1px solid var(--border-color);
        box-sizing: border-box;
        border-radius: 6px;
        box-shadow: none;
        padding:16px;
        flex-shrink: 0;
        border-top:6px solid var(--primary-bg-color)
    }

    .session_section_label__3MXZ_ {
        font-size: 14px;
        line-height: 17px;
    }

    .session_section_container__1V_ie:first-of-type {
       padding-bottom:32px;
       margin-bottom:32px;
       border-bottom:1px solid var(--border-color);
    }

    .session_section_container__1V_ie:last-of-type {
        padding-top:32px;
        margin:0;
        margin-top: 32px;
        border-top:1px solid var(--border-color);
     }

    .session_sub_title__h9Foy {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: var(--secondary-text-color);
    }

    .session_price__QDB1p {
        margin:0;
        font-size: 18px;
        line-height: 22px;
        color: var(--secondary-text-color);
    }

    .session_right_content__1d4BB {
        padding:0;
    }

    .session_information__1ueTU {
        margin-bottom:24px;
    }

    .session_information_title__QH7ce {
        font-size: 14px;
    }

    .session_information_text__Jmnc3{
        font-size: 13px;
    }

    .session_purchase_button__2LZ6G {
        position:relative;
        left:0;
        bottom:0;
        text-align: left;
    }
}

@media(min-width: 1024px) {
    .session_left_container__2ZvSd {
        max-width:380px;
    }

    .session_left_content__zVu1K {
        width: 100%;
        padding:32px;
    }
}

.customizePlayer_container__3b5g8 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-top: 8px;
}

.customizePlayer_input__26-At {
  flex: 1 1;
  width: 106px;
}

.customizePlayer_first_input__3-t1N {
  margin-right: 20px;
}

.customizePlayer_separator__2JD0J {
  width: 24px;
}

.customizePlayer_buttons__zdFr0 {
  display:flex;
  align-items: center;
  justify-content: space-between;
  margin:32px -12px 0;
}

.customizePlayer_buttons__item__1zmZQ {
  width: 100%;
  padding:0 12px;
}

.customizePlayer_buttons__item__1zmZQ > button {
  width: 100%;
}

@media(min-width: 768px) {
  .customizePlayer_input__26-At {
    flex: 1 1;
    width: 209px;
  }
}

.customizePlayer_buy_card__2W9s_ {
    margin: 0;
    font-size: 14px;
    line-height: 17px;
    color: var(--links-color);
}

.customizePlayer_buttons__zdFr0 {
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin-top:32px;
}
.paymentConfirmation_main__1bKYp {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 600px;
  height: 1px;
}

.paymentConfirmation_left_content__166yb {
  z-index: 2;
}

.paymentConfirmation_right_content__39BKe, .paymentConfirmation_left_content__166yb {
  background-color: white;
  box-sizing: border-box;
  padding: 24px 16px;
}

.paymentConfirmation_section_container__1wGd6 {
  display: flex;
  flex: 1 1;
  width: 100%;
  align-items: center;
  margin-left: 20px;
}

.paymentConfirmation_section_container__1wGd6:first-of-type {
  margin-bottom: 10px;
  margin-left: 0px;
}

.paymentConfirmation_section_container__1wGd6:last-of-type {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  border-top: solid 1px var(--input-bg-hover);
  padding-top: 20px;
}

.paymentConfirmation_information_container__DuOi9 {
  flex: 2 1;
  margin-left: 16px;
}

.paymentConfirmation_title__2y8bo {
  margin: 0px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--tertiary-text-color);
}

.paymentConfirmation_sub_title__1mKbE {
  margin: 0px;
  font-size: 14px;
  color: #6D7876; /* only used here */
}

.paymentConfirmation_price__fud7i {
  font-size: 19px;
  margin-right: 10px;
  margin-left: 10px;
}

.paymentConfirmation_section_label__3VW6w {
  font-size: 14px;
  margin-left: 20px;
}

.paymentConfirmation_title_right__1TJF2 {
  font-weight: bold;
  font-size: 24px;
  color: var(--primary-bg-color);
  margin: 0;
  margin-bottom: 23px;
}

.paymentConfirmation_explanation__VoF0I {
  font-size: 14px;
  line-height: 20px;
  color: #4F5D53;
}

.paymentConfirmation_subtitle__NVFWq {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 0;
  color: var(--tertiary-text-color);
}

.paymentConfirmation_fees_container__Tcdml {
  padding: 16px 24px;
  border-radius: 6px;
  background-color: var(--gold-200);
  margin-bottom: 39px;
}

.paymentConfirmation_fees_title__3JVmu {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 4px 0;
}

.paymentConfirmation_fees_description__25KSi {
  font-size: 13px;
  line-height: 18px;
  color: var(--gold-800);
  margin: 0;
}

.paymentConfirmation_player__2HQnQ {
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px var(--input-bg-hover);
}

.paymentConfirmation_player__2HQnQ p {
  color: var(--tertiary-text-color);
  margin: 0;
}

.paymentConfirmation_player_name__1zHHM {
  font-size: 14px;
}

.paymentConfirmation_player_price__2IyiI {
  font-size: 19px;
  font-weight: 500;
}

.paymentConfirmation_player_price_container__o0WEW {
  display: flex;
  align-items: center;
}

.paymentConfirmation_player_customize__3SQGZ {
  display: flex;
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
}

.paymentConfirmation_player_customize__3SQGZ > p {
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  vertical-align: middle;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: var(--links-color);
  margin-right: 32px;
  margin-left: 10px;
}

.paymentConfirmation_total__30O6L {
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paymentConfirmation_total__30O6L p {
  margin: 0;
}

.paymentConfirmation_total_name__9Xvqm {
  color: #6D7876;
  font-size: 14px;
}

.paymentConfirmation_total_price__22mc_ {
  font-size: 19px;
  font-weight: 500;
  color: var(--tertiary-text-color);
}

.paymentConfirmation_total_price_container__Ul65B {
  display: flex;
  align-items: center;
}

.paymentConfirmation_back_button__oCvJx > button {
  background: var(--primary-bg-color);
}

@media(min-width: 768px) {
  .paymentConfirmation_main__1bKYp {
    flex-direction: row;
    justify-content: center;
  }

  .paymentConfirmation_left_content__166yb {
    border-top-left-radius: 30px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 300px;
  }

  .paymentConfirmation_right_content__39BKe {
    padding: 32px 40px 40px 40px;
    max-width: 540px;
    width: 600px;
  }

  .paymentConfirmation_left_content__166yb, .paymentConfirmation_right_content__39BKe {
    position: relative;
    height: 100%;
  }

  .paymentConfirmation_price__fud7i, .paymentConfirmation_section_container__1wGd6 {
    margin: 0;
  }

  .paymentConfirmation_back_button__oCvJx {
    max-width: 255px;
  }
}

.paymentConfirmation_golf_icon__26SGN {
    display:flex;
    align-items:center;
    justify-content:center;
    width:80px;
    height:80px;
    background: #D8EFCD;
    border-radius: 6px;
}

.paymentConfirmation_section_container__1wGd6 {
    margin-bottom:18px;
}

.paymentConfirmation_section_container__1wGd6:first-of-type {
    margin-bottom:16px;
}

.paymentConfirmation_section_container__1wGd6:last-of-type {
    position: relative;
    padding-top:12px;
}

.paymentConfirmation_right_content__39BKe {
    padding:0 16px 24px;
}

.paymentConfirmation_title__2y8bo {
    margin-bottom:2px;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: var(--secondary-text-color);
}
.paymentConfirmation_sub_title__1mKbE {
    margin: 0px;
    font-size: 12px;
    line-height: 15px;
    color: var(--tertiary-text-color);
}

.paymentConfirmation_price__fud7i {
    display: block;
    margin:6px 0 0;
    font-size: 19px;
    font-weight:600;
}

.paymentConfirmation_title_right__1TJF2{
    margin: 0 0 24px 0;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 37px;
    color: var(--primary-text-color);
}

.paymentConfirmation_explanation__VoF0I {
    margin:0 0 24px;
    font-size: 15px;
    line-height: 160%;
    color: var( --tertiary-text-color);
}

.paymentConfirmation_information__2HDCb {
    margin-bottom:37.5px;
    padding:16px;
    background: #F1E9DA;
    border-radius: 6px;
}

.paymentConfirmation_information__2HDCb p {
    margin:0;
}

.paymentConfirmation_information_title__1SJKp {
    margin-bottom:4px;
    font-weight: 600;
    font-size: 13px;
    line-height: 160%;
    color:#4A391C;
}

.paymentConfirmation_information_text__RV6sg{
    font-size: 12px;
    line-height: 160%;
    color: #73592B;
}

.paymentConfirmation_information_link__3g2cA{
    display:inline-block;
    margin-top:12px;
    font-size: 11px;
    font-weight:700;
    line-height: 13px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #007031;
    cursor: pointer;
}

.paymentConfirmation_section_label__3VW6w {
    margin: 0;
    margin-left: 12px;
    font-size: 13px;
    line-height: 16px;
    color:var(--secondary-text-color);
}

.paymentConfirmation_subtitle__NVFWq {
    margin-bottom:27.5px;
    font-family: 'Titillium Web', sans-serif;
    font-size: 19px;
    font-weight:400;
    line-height: 130%;
    color: var(--secondary-text-color);
}

.paymentConfirmation_subtitle__NVFWq svg {
    margin-right:13px;
}

.paymentConfirmation_player__2HQnQ {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom:16px;
    padding: 27px 16px;
    border: solid 1px var(--border-color);
    border-radius: 6px;
}

.paymentConfirmation_player__2HQnQ p {
    color: var(--secondary-text-color);
    margin: 0;
}

.paymentConfirmation_player_name__1zHHM {
    font-size: 14px;
    line-height: 17px;
}

.paymentConfirmation_player_price__2IyiI {
    font-size: 16px;
    font-weight:600;
    line-height: 16px;
    color: var(--secondary-text-color);
}

.paymentConfirmation_total__30O6L {
    padding: 0;
    padding: 16px;
    border: solid 1px var(--border-color);
    border-radius: 6px;
}

.paymentConfirmation_total__30O6L p {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--secondary-text-color);
}

.paymentConfirmation_back_button__oCvJx {
    margin-top:32px;
    text-align: right;
}

@media(min-width: 768px) {
    .paymentConfirmation_main__1bKYp {
        padding:0 16px;
        min-height: auto;
        height:auto;
    }
    .paymentConfirmation_title__2y8bo {
        font-size: 18px;
        line-height: 107.7%;
        color: #3A3A39;
    }

    .paymentConfirmation_left_content__166yb {
        width: 100%;
        max-width:300px;
        border: 1px solid var(--border-color);
        box-sizing: border-box;
        border-radius: 6px;
        box-shadow: none;
        padding:16px;
        margin-right:32px;
        flex-shrink: 0;
        border-top:6px solid var(--primary-bg-color)
    }

    .paymentConfirmation_section_label__3VW6w {
        font-size: 14px;
        line-height: 17px;
    }

    .paymentConfirmation_section_container__1wGd6:first-of-type {
       padding-bottom:32px;
       margin-bottom:32px;
       border-bottom:1px solid var(--border-color);
    }

    .paymentConfirmation_section_container__1wGd6:last-of-type {
        padding-top:32px;
        margin:0;
        margin-top: 32px;
        border-top:1px solid var(--border-color);
     }

    .paymentConfirmation_sub_title__1mKbE {
        font-size: 14px;
        line-height: 160%;
        color: #929290;
    }

    .paymentConfirmation_price__fud7i {
        margin:0;
        font-size: 18px;
        line-height: 22px;
        color: var(--secondary-text-color);
    }

    .paymentConfirmation_explanation__VoF0I {
        margin:0 0 32px;
    }

    .paymentConfirmation_price__fud7i {
        margin:0;
        font-size: 18px;
        line-height: 22px;
        color: var(--secondary-text-color);
    }

    .paymentConfirmation_information__2HDCb {
        margin-bottom:43px;
    }

    .paymentConfirmation_information_title__1SJKp {
        font-size: 14px;
    }

    .paymentConfirmation_information_text__RV6sg{
        font-size: 13px;
    }

    .paymentConfirmation_information_link__3g2cA {
        margin-top:16px;
        font-size: 11px;
    }

    .paymentConfirmation_title_right__1TJF2{
        margin: 0 0 32px 0;
        font-size: 32px;
        line-height: 49px
    }

    .paymentConfirmation_back_button__oCvJx {
        margin-top:32px;
        text-align: left;
    }
}

@media(min-width: 1024px) {
    .paymentConfirmation_left_content__166yb {
        max-width:380px;
        padding:32px;
    }
}

.breadcrumbs_container__1Tytw {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 27px;
  margin-top: 20px;
}

.breadcrumbs_item__cf--E {
  display: flex;
}

.breadcrumbs_text__3JTrC {
  color: var(--white);
  font-size: 15px;
}

.breadcrumbs_dot__1gUmK {
  margin: 0px 5px 0px 5px;
}

.breadcrumbs_selected__1mg20 {
  color: var(--primary-text-color);
}

.breadcrumbs_separator__3PGEk {
  margin: 0 17px 0 17px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .breadcrumbs_container__1Tytw {
    background-color: #0C3229;
    margin: 0;
  }
}

.breadcrumbs_container__1Tytw {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    margin:0 16px 0 40px;
    background-color: transparent;
}
  
.breadcrumbs_text__3JTrC {
  color: var(--tertiary-text-color);
  font-size: 15px;
  text-decoration: none;
}

.breadcrumbs_selected__1mg20 {
  font-weight:500;
  color: var(--primary-text-color);
}
.langSwitcher_langs__1dwh5::before {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 8px;
  content: '';
}

.langSwitcher_langs__1dwh5 {
  margin-left: auto;
  position: relative;
}

.langSwitcher_langs__1dwh5 button {
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
  border: none;
  background: none;
}

.langSwitcher_langs__1dwh5 button svg {
  margin-left: 16px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  width: 7px !important;
}

.langSwitcher_langs__1dwh5:hover ul, .langSwitcher_langs__1dwh5.langSwitcher_active__1kxix ul {
  display: block;
  top: calc(100% + 8px);
  max-height: 200px;
  overflow: hidden;
  opacity: 1;
}

.langSwitcher_langs__1dwh5 ul {
  display: none;
  padding: 20px;
  margin: 0;
  z-index: 3;
  position: absolute;
  top: calc(100% + 8px);
  overflow: hidden;
  opacity: 0;
  left: 50%;
  background: #fff;
  box-shadow: 0px 12px 44px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transition: bottom 0.1s linear;
  transition: bottom 0.1s linear;
}
.langSwitcher_langs__1dwh5 ul li:last-child {
  margin-bottom: 0;
}

.langSwitcher_langs__1dwh5 ul li {
  cursor: pointer;
  margin-bottom: 16px;
  list-style: none;
}

@media only screen and (min-width: 768px) {
  .langSwitcher_langs__1dwh5 {
    order: 2;
  }
  .langSwitcher_langs__1dwh5 ul {
    z-index: 2;
  }
}

body {
  background-color: var(--background-color);
}

.app_logo__2MpWO {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}

.app_logo__2MpWO > img {
  height: 36px;
}

@media only screen and (max-width: 768px) {
  .app_logo__2MpWO {
    margin: 16px 0 16px 0;
  }
}

body {
    background-color: var(--background-color);
    font-family: 'Inter',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.app_header__2jcQB {
  display:flex;
  align-items: center;
  justify-content: space-between;
  border-bottom:1px solid #E6E7E9;
  padding-right: 16px;
}

.app_logo__2MpWO {
  display: flex;
  justify-content: center;
  align-items: center;
  height:72px;
  width:68px;
  background-color: #003216;
  margin: 0;
}

.app_logo__2MpWO > img {
    height: 53px;
}

@media only screen and (min-width: 768px) {
  .app_header__2jcQB {
    margin-bottom:40px;
  }
}

@media only screen and (min-width: 1024px) {
  .app_header__2jcQB {
    justify-content:flex-start;
  }

  .app_logo__2MpWO {
    width:105px;
    height:111px;
  }

  .app_logo__2MpWO > img {
    height: 80px;
  }
}


@media only screen and (min-width: 768px) {
  .app_header__2jcQB {
    padding-right: 53px;
  }
}

