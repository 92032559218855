/* Check icon for login right part */
.customCheck {
  display: flex;
}
.customCheck > .check {
  position: relative;
  right: 17px
}

.customCheck > .check {
  position: relative;
  right: inherit;
  margin-right:12px;
}
