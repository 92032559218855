.langs::before {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 8px;
  content: '';
}

.langs {
  margin-left: auto;
  position: relative;
}

.langs button {
  display: flex;
  align-items: center;
  outline: none;
  cursor: pointer;
  border: none;
  background: none;
}

.langs button svg {
  margin-left: 16px;
  transform: rotate(90deg);
  width: 7px !important;
}

.langs:hover ul, .langs.active ul {
  display: block;
  top: calc(100% + 8px);
  max-height: 200px;
  overflow: hidden;
  opacity: 1;
}

.langs ul {
  display: none;
  padding: 20px;
  margin: 0;
  z-index: 3;
  position: absolute;
  top: calc(100% + 8px);
  overflow: hidden;
  opacity: 0;
  left: 50%;
  background: #fff;
  box-shadow: 0px 12px 44px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  transform: translateX(-50%);
  transition: bottom 0.1s linear;
}
.langs ul li:last-child {
  margin-bottom: 0;
}

.langs ul li {
  cursor: pointer;
  margin-bottom: 16px;
  list-style: none;
}

@media only screen and (min-width: 768px) {
  .langs {
    order: 2;
  }
  .langs ul {
    z-index: 2;
  }
}
