.container {
  z-index: 9999;
}

h1 {
  margin: 0;
}

.overlay_visible {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--black);
  opacity: 0.3;
}

.overlay_hidden {
  display: none;
}

.title {
  color: var(--primary-text-color);
  margin-top: 8px;
  margin-bottom: 24px;
}

@keyframes showModalMobile {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes hideModalMobile {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes showModal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes hideModal {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes showModalMobile {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes hideModalMobile {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(150%);
  }
}

.modal {
  position: fixed;
  background-color: var(--white);
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0px 12px 44px rgba(0, 0, 0, 0.11);
}

.modal_visible {
  display: initial !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 450px;
  animation: showModal 300ms;
}

.modal_hidden {
  display: none;
}

.modal_hidden_active {
  display: initial !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 450px;
  animation: hideModal 300ms;
}

.close_button {
  display: none;
}

@media only screen and (max-width: 768px) {
  .modal {
    border-radius: unset;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    max-width: none;
    transform: none;
    top: initial;
    padding: 16px;
    box-shadow: none;
  }

  .modal_visible {
    display: initial !important;
    right: 0;
    left: 0;
    bottom: 0;
    animation: showModalMobile 300ms;
  }

  .modal_hidden_active {
    display: initial !important;
    right: 0;
    left: 0;
    bottom: 0;
    animation: hideModalMobile 300ms;
  }

  .close_button {
    display: initial;
    position: absolute;
    top: 0;
    right: 16px;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    outline: none;
  }
}

.modal {
    border-radius: unset;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 24px 16px 16px;
    box-shadow: none;
}

.title {
    margin-top: 0;
    margin-bottom: 24px;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 400;
    color: var(--secondary-text-color);
}

.buy_card {
    font-size: 14px;
    line-height: 17px;
    color: var(--links-color);
}

@media only screen and (min-width: 768px) {
    .modal {
        padding:40px;
        border-radius:6px;
    }
}