.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 600px;
  height: 1px;
}

.left_content {
  z-index: 2;
}

.right_content, .left_content {
  background-color: white;
  box-sizing: border-box;
  padding: 24px 16px;
}

.section_container {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  margin-left: 20px;
}

.section_container:first-of-type {
  margin-bottom: 10px;
  margin-left: 0px;
}

.section_container:last-of-type {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  border-top: solid 1px var(--input-bg-hover);
  padding-top: 20px;
}

.information_container {
  flex: 2;
  margin-left: 16px;
}

.title {
  margin: 0px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--tertiary-text-color);
}

.sub_title {
  margin: 0px;
  font-size: 14px;
  color: #6D7876; /* only used here */
}

.price {
  font-size: 19px;
  margin-right: 10px;
  margin-left: 10px;
}

.section_label {
  font-size: 14px;
  margin-left: 20px;
}

.title_right {
  font-weight: bold;
  font-size: 24px;
  color: var(--primary-bg-color);
  margin: 0;
  margin-bottom: 23px;
}

.explanation {
  font-size: 14px;
  line-height: 20px;
  color: #4F5D53;
}

.subtitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 0;
  color: var(--tertiary-text-color);
}

.fees_container {
  padding: 16px 24px;
  border-radius: 6px;
  background-color: var(--gold-200);
  margin-bottom: 39px;
}

.fees_title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 4px 0;
}

.fees_description {
  font-size: 13px;
  line-height: 18px;
  color: var(--gold-800);
  margin: 0;
}

.player {
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px var(--input-bg-hover);
}

.player p {
  color: var(--tertiary-text-color);
  margin: 0;
}

.player_name {
  font-size: 14px;
}

.player_price {
  font-size: 19px;
  font-weight: 500;
}

.player_price_container {
  display: flex;
  align-items: center;
}

.player_customize {
  display: flex;
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
}

.player_customize > p {
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  vertical-align: middle;
  text-decoration-line: underline;
  color: var(--links-color);
  margin-right: 32px;
  margin-left: 10px;
}

.total {
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total p {
  margin: 0;
}

.total_name {
  color: #6D7876;
  font-size: 14px;
}

.total_price {
  font-size: 19px;
  font-weight: 500;
  color: var(--tertiary-text-color);
}

.total_price_container {
  display: flex;
  align-items: center;
}

.back_button > button {
  background: var(--primary-bg-color);
}

@media(min-width: 768px) {
  .main {
    flex-direction: row;
    justify-content: center;
  }

  .left_content {
    border-top-left-radius: 30px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 300px;
  }

  .right_content {
    padding: 32px 40px 40px 40px;
    max-width: 540px;
    width: 600px;
  }

  .left_content, .right_content {
    position: relative;
    height: 100%;
  }

  .price, .section_container {
    margin: 0;
  }

  .back_button {
    max-width: 255px;
  }
}

.golf_icon {
    display:flex;
    align-items:center;
    justify-content:center;
    width:80px;
    height:80px;
    background: #D8EFCD;
    border-radius: 6px;
}

.section_container {
    margin-bottom:18px;
}

.section_container:first-of-type {
    margin-bottom:16px;
}

.section_container:last-of-type {
    position: relative;
    padding-top:12px;
}

.right_content {
    padding:0 16px 24px;
}

.title {
    margin-bottom:2px;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: var(--secondary-text-color);
}
.sub_title {
    margin: 0px;
    font-size: 12px;
    line-height: 15px;
    color: var(--tertiary-text-color);
}

.price {
    display: block;
    margin:6px 0 0;
    font-size: 19px;
    font-weight:600;
}

.title_right{
    margin: 0 0 24px 0;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 37px;
    color: var(--primary-text-color);
}

.explanation {
    margin:0 0 24px;
    font-size: 15px;
    line-height: 160%;
    color: var( --tertiary-text-color);
}

.information {
    margin-bottom:37.5px;
    padding:16px;
    background: #F1E9DA;
    border-radius: 6px;
}

.information p {
    margin:0;
}

.information_title {
    margin-bottom:4px;
    font-weight: 600;
    font-size: 13px;
    line-height: 160%;
    color:#4A391C;
}

.information_text{
    font-size: 12px;
    line-height: 160%;
    color: #73592B;
}

.information_link{
    display:inline-block;
    margin-top:12px;
    font-size: 11px;
    font-weight:700;
    line-height: 13px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #007031;
    cursor: pointer;
}

.section_label {
    margin: 0;
    margin-left: 12px;
    font-size: 13px;
    line-height: 16px;
    color:var(--secondary-text-color);
}

.subtitle {
    margin-bottom:27.5px;
    font-family: 'Titillium Web', sans-serif;
    font-size: 19px;
    font-weight:400;
    line-height: 130%;
    color: var(--secondary-text-color);
}

.subtitle svg {
    margin-right:13px;
}

.player {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom:16px;
    padding: 27px 16px;
    border: solid 1px var(--border-color);
    border-radius: 6px;
}

.player p {
    color: var(--secondary-text-color);
    margin: 0;
}

.player_name {
    font-size: 14px;
    line-height: 17px;
}

.player_price {
    font-size: 16px;
    font-weight:600;
    line-height: 16px;
    color: var(--secondary-text-color);
}

.total {
    padding: 0;
    padding: 16px;
    border: solid 1px var(--border-color);
    border-radius: 6px;
}

.total p {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--secondary-text-color);
}

.back_button {
    margin-top:32px;
    text-align: right;
}

@media(min-width: 768px) {
    .main {
        padding:0 16px;
        min-height: auto;
        height:auto;
    }
    .title {
        font-size: 18px;
        line-height: 107.7%;
        color: #3A3A39;
    }

    .left_content {
        width: 100%;
        max-width:300px;
        border: 1px solid var(--border-color);
        box-sizing: border-box;
        border-radius: 6px;
        box-shadow: none;
        padding:16px;
        margin-right:32px;
        flex-shrink: 0;
        border-top:6px solid var(--primary-bg-color)
    }

    .section_label {
        font-size: 14px;
        line-height: 17px;
    }

    .section_container:first-of-type {
       padding-bottom:32px;
       margin-bottom:32px;
       border-bottom:1px solid var(--border-color);
    }

    .section_container:last-of-type {
        padding-top:32px;
        margin:0;
        margin-top: 32px;
        border-top:1px solid var(--border-color);
     }

    .sub_title {
        font-size: 14px;
        line-height: 160%;
        color: #929290;
    }

    .price {
        margin:0;
        font-size: 18px;
        line-height: 22px;
        color: var(--secondary-text-color);
    }

    .explanation {
        margin:0 0 32px;
    }

    .price {
        margin:0;
        font-size: 18px;
        line-height: 22px;
        color: var(--secondary-text-color);
    }

    .information {
        margin-bottom:43px;
    }

    .information_title {
        font-size: 14px;
    }

    .information_text{
        font-size: 13px;
    }

    .information_link {
        margin-top:16px;
        font-size: 11px;
    }

    .title_right{
        margin: 0 0 32px 0;
        font-size: 32px;
        line-height: 49px
    }

    .back_button {
        margin-top:32px;
        text-align: left;
    }
}

@media(min-width: 1024px) {
    .left_content {
        max-width:380px;
        padding:32px;
    }
}
