body {
  background-color: var(--background-color);
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}

.logo > img {
  height: 36px;
}

@media only screen and (max-width: 768px) {
  .logo {
    margin: 16px 0 16px 0;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Titillium+Web:wght@300;400;600;700&display=swap');

body {
    background-color: var(--background-color);
    font-family: 'Inter',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.header {
  display:flex;
  align-items: center;
  justify-content: space-between;
  border-bottom:1px solid #E6E7E9;
  padding-right: 16px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height:72px;
  width:68px;
  background-color: #003216;
  margin: 0;
}

.logo > img {
    height: 53px;
}

@media only screen and (min-width: 768px) {
  .header {
    margin-bottom:40px;
  }
}

@media only screen and (min-width: 1024px) {
  .header {
    justify-content:flex-start;
  }

  .logo {
    width:105px;
    height:111px;
  }

  .logo > img {
    height: 80px;
  }
}


@media only screen and (min-width: 768px) {
  .header {
    padding-right: 53px;
  }
}
