/* @TODO FOR TEST PURPOSE, DELETE LATER*/
:root {
  --primary-bg-color: #c4492c;
  --primary-hover-bg-color: #a33419;
  --primary-active-bg-color: #842a15;
  --secondary-bg-color: #006534;
  --secondary-hover-bg-color: #003d20;
  --secondary-active-bg-color: #002412;
  --background-color: #05231C;
  --links-color: #2c5a97;
  --primary-text-color: #c4492c;
  --secondary-text-color: #4f4f4f;
  --tertiary-text-color: #00231B;
  --white: #fff;
  --black: #000;
  --red: #c4492c;
  --gold-200: #f1e9da;
  --gold-800: #73592b;
  --input-bg-base: #f2f2f2;
  --input-bg-hover: #e6e6e6;
  --input-border-error: #D81F1F;
}

@media only screen and (min-width: 769px) {
  .only_mobile {
    display: none !important;
  }

  /* @XXX Put here because id not detect on module */
  div#no_refund {
    max-width: 300px;
  }
}

@media only screen and (max-width: 768px) {
  .only_desktop {
    display: none !important;
  }

  /* @XXX Put here because id not detect on module */
  div#no_refund {
    max-width: 200px;
  }
}

:root {
    --primary-bg-color: #007031;
    --secondary-bg-color: transparent;
    --background-color: #fff;    
    --primary-text-color: #231F20;
    --primary-hover-bg-color : #00471F;
    --primary-active-bg-color : #007031;
    --secondary-text-color: #3A3A39;
    --tertiary-text-color: #676765; 
    --links-color: #3182CE;
    --border-color:#E6E7E9;
    --border-color-hover: #CBCCCD;
    --border-color-focus: #008A3D;
}  
