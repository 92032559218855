.main {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 7px;
}

.message {
  margin-left: 10px;
  font-size: 12px;
  color: var(--input-border-error);
}

.main {
    justify-content: flex-start;
}