.main {
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  height: 45px;
  line-height: 15px;
  letter-spacing: 0.2em;
  outline: none;
  text-transform: uppercase;
  width: 100%;
}

.primary {
  background-color: var(--primary-bg-color);
}

.primary:hover {
  background-color: var(--primary-hover-bg-color);
}

.primary:active {
  background-color: var(--primary-active-bg-color);
}

.secondary {
  background-color: var(--secondary-bg-color);
}

.secondary:hover {
  background-color: var(--secondary-hover-bg-color);
}

.secondary:active {
  background-color: var(--secondary-active-bg-color);
}

.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.disabled.primary:hover {
  background-color: var(--primary-bg-color);
}

.disabled.secondary:hover {
  background-color: var(--secondary-bg-color);
}
.main {
  outline: none;
  border: none;
  border-radius: 6px;
  width: auto;
  height: 48px;
  padding : 0 32px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  line-height: 15px;
  letter-spacing: 0.1em;
  color: #FFFFFF;
  text-transform: uppercase;
}

.primary {
  background-color: var(--primary-bg-color);
}

.primary:hover {
  background-color: var(--primary-hover-bg-color);
}

.primary:active {
  background-color: var(--primary-active-bg-color);
}

.secondary {
  background-color: var(--white);
  border: 2px solid #E6E7E9;
  box-sizing: border-box;
  border-radius: 6px;
  color: var(--secondary-text-color); 
}

.tertiary {
  background-color:transparent;
  height:auto;
  padding:0;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  text-align: right;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--primary-bg-color);
} 

.secondary:hover {
  background-color: var(--white);
  border: 2px solid #CBCCCD;
}

.secondary:active {
  background-color: var(--white);
  border: 2px solid #E6E7E9;
}
  
.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
  
.disabled.primary:hover {
  background-color: var(--primary-bg-color);
}
  
.disabled.secondary:hover {
  background-color: var(--secondary-bg-color);
}