.container {
  z-index: 9999;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 13px;
}

.content {
  margin: 0;
  color: var(--tertiary-text-color);
  font-size: 14px;
}

@keyframes showSnackBarMobile {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes hideSnackBarMobile {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes showSnackBar {
  from {
    transform: translateX(calc(100% + 24px));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes hideSnackBar {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(100% + 24px));
  }
}

.snack_bar {
  position: fixed;
  top: 24px;
  right: 24px;
  align-items: center;
  flex-direction: row;
  background-color: var(--white);
  border-radius: 6px;
  padding: 17px 24px 17px 24px;
  max-width: 400px;
  animation-timing-function: cubic-bezier(0.345, 0.355, 0.000, 1.000);
  animation-duration: 300ms;
}

.snack_bar_visible {
  display: flex !important;
  animation-name: showSnackBar;
}

.snack_bar_hidden {
  display: none;
  animation-name: hideSnackBar;
}

.snack_bar_hidden_active {
  display: flex !important;
  animation-name: hideSnackBar;
}

@media only screen and (max-width: 768px) {
  .snack_bar {
    top: 0;
    right: 0;
    left: 0;
    background-color: rgb(26,26,26, 0.9);
    max-width: none;
    height: 32px;
    border-radius: 0;
    justify-content: center;
  }

  .snack_bar_visible {
    display: flex !important;
    animation-name: showSnackBarMobile;
  }

  .snack_bar_hidden_active {
    display: flex !important;
    animation-name: hideSnackBarMobile;
  }

  .snack_bar_hidden {
    display: none;
    animation-name: hideSnackBarMobile;
  }

  .content {
    color: var(--white);
  }
}
