.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 27px;
  margin-top: 20px;
}

.item {
  display: flex;
}

.text {
  color: var(--white);
  font-size: 15px;
}

.dot {
  margin: 0px 5px 0px 5px;
}

.selected {
  color: var(--primary-text-color);
}

.separator {
  margin: 0 17px 0 17px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .container {
    background-color: #0C3229;
    margin: 0;
  }
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    margin:0 16px 0 40px;
    background-color: transparent;
}
  
.text {
  color: var(--tertiary-text-color);
  font-size: 15px;
  text-decoration: none;
}

.selected {
  font-weight:500;
  color: var(--primary-text-color);
}