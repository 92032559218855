.main {
  display: flex;
  position: relative;
  flex-direction: column;
}

.label {
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
}

.required:after {
  content:'*';
}

.select {
  border: none;
  outline: none;
  height: 46px;
  padding: 0 24px 0 24px;
  background-color: var(--input-bg-base);
  border-radius: 100px;
  width: 100%;
  display: flex;
  align-items: center;
}

.error {
  border: 1px solid var(--input-border-error);
  height: 44px;
  padding: 0 23px 0 23px;
}

.label {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: var(--secondary-text-color);
}

.select {
    border: none;
    outline: none;
    height: 48px;
    padding: 0;
    border: 1px solid #E6E7E9;
    background-color: var(--white);
    border-radius: 6px;
    font-size: 14px;
    line-height: 17px;
}