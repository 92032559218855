.main {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.main input {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.main input:checked ~ .checkbox {
  background-color: #FAFAFA;
  border-color: var(--primary-bg-color);
}

.main input:checked ~ .checkbox:after {
  display: block;
}

.main:hover .chekbox {
  border-color: #CBCCCD;
}

.label {
  margin-left: 2px;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary-bg-color);
}

.label > a {
  position: relative;
  z-index: 1;
}

.checkbox {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  min-width: 22px;
  margin-right: 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.checkbox:after {
  content: "";
  position: absolute;
  display: none;
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid var(--primary-bg-color);
  border-width: 0 1.8px 1.8px 0;
  transform: rotate(45deg);
}
