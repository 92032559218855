.main {
  display: flex;
  position: relative;
  flex-direction: column;
}

.label {
  text-align: left;
  font-weight:bold;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
}

.required:after {
  content:'*';
}

.input {
  border: none;
  outline: none;
  height: 46px;
  padding: 0 24px 0 24px;
  background-color: var(--input-bg-base);
  border-radius: 100px;
}

.input:focus {
  background-color: var(--input-bg-hover)
}

.input:hover {
  background-color: var(--input-bg-hover)
}

.error {
  border: 1px solid var(--input-border-error);
  height: 44px;
  padding: 0 23px 0 23px;
}

.label {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: var(--secondary-text-color);
}

.input {
    border: none;
    outline: none;
    height: 48px;
    padding: 0 16px;
    border: 1px solid var(--border-color);
    background-color: var(--white);
    border-radius: 6px;
    font-size: 14px;
    line-height: 17px;
}
  
.input::placeholder {
    color:#929290;
}
  
.input:focus {
    border: 1px solid var(--border-color-focus);
    background-color: var(--white);
}
  
.input:hover {
    border: 1px solid var(--border-color-hover);
    background-color: var(--white);
}
  
.error {
    border: 1px solid var(--input-border-error);
    height: 44px;
    padding: 0 23px 0 23px;
}
  