.main {
  display: inline-block;
  position: relative;
  width: 70px;
  height: 30px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bubble {
  position: absolute;
  top: 12px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--white);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.primary {
  background: var(--primary-bg-color);
}

.firstBubble {
  left: 8px;
  animation: firstBubbleAnimation 0.6s infinite;
}

.secondBubble {
  left: 8px;
  animation: secondBubbleAnimation 0.6s infinite;
}

.thirdBubble {
  left: 32px;
  animation: secondBubbleAnimation 0.6s infinite;
}

.fourthBubble {
  left: 56px;
  animation: thirdBubbleAnimation 0.6s infinite;
}

@keyframes firstBubbleAnimation {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes secondBubbleAnimation {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

@keyframes thirdBubbleAnimation {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
