.main {
  position: relative;
}

.desktop_container {
  height: 100%;
}

.container {
  border-radius: 6px;
  border: 1px solid #CDCDCD;
  overflow: hidden;
  display: block;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.button_opened {
  transform: translate(-50%, 50%) rotate(180deg);
}

.main {
    position: relative;
}

.desktop_container {
    height: 100%;
}

.container {
    border: 1px solid var(--border-color);
    padding: 5px;
    overflow: hidden;
}

.button {
    width: 24px;
    height: 24px;
    background-color:var(--primary-bg-color);
    border-radius: 12px;
}

.button_opened {
    transform: translate(-50%, 50%) rotate(180deg);
}
