.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 600px;
  height: 1px;
}

.left_content {
  z-index: 2;
}

.limit {
  text-align: left;
  padding: 24px 16px;
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-text-color);
}

.limitMsg {
  background: #fff6eb;
  font-size: 12px;
  line-height: 15px;
  padding: 12px 16px;
  text-align: center;
  color: #9c3821;
  border-radius: 6px;
  display: block;
}

.right_content,
.left_content {
  background-color: white;
  box-sizing: border-box;
  padding: 24px 16px;
}

.section_container {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  margin-left: 20px;
}

.section_container:first-of-type {
  margin-bottom: 10px;
  margin-left: 0px;
}

.section_container:last-of-type {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  border-top: solid 1px var(--input-bg-hover);
  padding-top: 20px;
}

.information_container {
  flex: 2;
  margin-left: 16px;
}

.title {
  margin: 0px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--tertiary-text-color);
}

.sub_title {
  margin: 0px;
  font-size: 14px;
  color: #6d7876; /* only used here */
}

.price {
  font-size: 19px;
  margin-right: 10px;
  margin-left: 10px;
}

.section_label {
  font-size: 14px;
  margin-left: 20px;
}

.title_right {
  font-weight: bold;
  font-size: 24px;
  color: var(--primary-bg-color);
  margin: 0;
  margin-bottom: 23px;
}

.info_right {
  font-family: "Titillium Web";
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
}

.player {
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px var(--input-bg-hover);
}

.player_name {
  margin: 0 0 8px;
  font-size: 14px;
}

.player_card {
  border-radius: 100px;
  padding: 6px 12px;
  font-size: 8px;
  font-weight: 700;
  line-height: 10px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  background: transparent;
  border: none;
}

.player_price {
  color: var(--tertiary-text-color);
  font-size: 19px;
  font-weight: 500;
}

.player_price_container {
  display: flex;
  align-items: center;
}

.player:first-of-type {
  cursor: default !important;
}

.player_customize {
  display: flex;
  align-items: center;
  outline: none;
  background: none;
  border: none;
  margin: 0 8px 0 0;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #00471f;
  cursor: pointer;
}

.player_customize > svg {
  margin-right: 8px;
}

.public_price {
  text-decoration-line: line-through;
  margin: 0 8px 0 0;
}

.purchase_button > button {
  margin-top: 53px;
  background: var(--primary-bg-color);
}

@media (min-width: 768px) {
  .main {
    flex-direction: row;
    justify-content: center;
  }

  .limit {
    text-align: center;
  }

  .left_container {
    position: relative;
    width: 100%;
    max-width: 300px;
  }

  .left_content {
    width: 100%;
    border-top-left-radius: 30px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }

  .player_card {
    margin-left: 8px;
  }

  .player_name_container {
    display: flex;
    align-items: center;
  }

  .player_name {
    margin: 0;
  }

  .player_customize {
    margin: 0 24px 0 0;
  }

  .player_customize_mobile {
    display: none;
  }

  .right_content {
    padding: 32px 40px 40px 40px;
    max-width: 540px;
    width: 600px;
  }

  .left_container,
  .right_content {
    position: relative;
    height: 100%;
  }

  .price,
  .section_container {
    margin: 0;
  }

  .purchase_button {
    position: absolute;
    bottom: 40px;
    right: 40px;
    width: 300px;
  }
}

.paiementFail {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 16px;
  z-index: 9999;
}

.paiementFail_box {
  padding: 24px;
  background-color: #fff;
  width: 100%;
  max-width: 512px;
}

.paiementFail_title {
  font-weight: 300;
  font-size: 26px;
  line-height: 40px;
  color: #3a3a39;
  margin-bottom: 24px;
  margin-top: 0;
}

.paiementFail_text {
  font-size: 14px;
  line-height: 160%;
  color: #3a3a39;
}

@media (min-width: 768px) {
  .paiementFail_box {
    padding: 40px;
  }
}
.golf_icon {
    display:flex;
    align-items:center;
    justify-content:center;
    width:80px;
    height:80px;
    background: #D8EFCD;
    border-radius: 6px;
}

.title {
    margin-bottom:2px;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: var(--secondary-text-color);
}

.sub_title {
    margin: 0px;
    font-size: 12px;
    line-height: 15px;
    color: var(--tertiary-text-color);
}

.price {
    display: block;
    margin:6px 0 0;
    font-size: 19px;
    font-weight:600;
}

.section_container {
    margin-bottom:18px;
}

.section_container:first-of-type {
    margin-bottom:16px;
 }

.section_container:last-of-type {
    position: relative;
    padding-top:12px;
}

.right_content {
    padding:0 16px 24px;
}

.title_right{
    margin: 0 0 16px 0;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 37px;
    color: var(--primary-text-color);
}

.information {
    margin-bottom:24px;
    padding:16px;
    background: #F1E9DA;
    border-radius: 6px;
}

.information p {
    margin:0;
}

.information_title {
    margin-bottom:4px;
    font-weight: 600;
    font-size: 13px;
    line-height: 160%;
    color:#4A391C;
}

.information_text{
    font-size: 12px;
    line-height: 160%;
    color: #73592B;
}

.section_label {
    margin: 0;
    margin-left: 12px;
    font-size: 13px;
    line-height: 16px;
    color:var(--secondary-text-color);
}

.player {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom:16px;
    padding: 27px 16px;
    border: solid 1px var(--border-color);
    border-radius: 6px;
}

.player:hover {
    border: 1px solid var(--border-color-hover);
    cursor:pointer;
}

.player_card {
    color: var(--primary-hover-bg-color);
    background-color: #D8EFCD;
    border:none;
}

.player_price {
    color: var(--secondary-text-color);
    margin: 0;
}

.player_name {
    font-size: 14px;
    line-height: 17px;
}

.public_price {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: line-through;
    color: #B1B2B3;
}

.player_customize > svg {
    display:none;
    margin-right:8px;
}

.player_customize > p {
    margin:0;
}

.purchase_button {
    text-align: right;
}

.purchase_button > button {
    margin-top: 32px;
}

.add_card {
    padding:24px;
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    border-radius: 6px;
}

.add_card_title {
    margin:0 0 24px;
    font-size: 19px;
    line-height: 130%;
    color: var(--secondary-text-color);
}

.add_card_submit {
    margin-top:24px;
    text-align:center;
}

.add_card_submit_right {
    margin-top:24px;
    text-align:right;
}

.add_card__error {
    background: #FFEDEB;
    padding:16px;
    margin-top:24px;
    border-radius: 6px;
    font-size: 13px;
    line-height: 160%;
    text-align: center;
    color: #9A1604;
}

.add_card__success {
    background: #F3FAF0;
    padding:16px;
    margin-top:24px;
    border-radius: 6px;
    font-size: 13px;
    line-height: 160%;
    text-align: center;
    color: var(--primary-hover-bg-color);
}

@media(min-width: 768px) {
    .main {
        padding:0 16px;
        min-height: auto;
        height:auto;
    }

    .left_container {
        width: 100%;
        max-width:300px;
        margin-right:32px;
    }

    .left_content {
        width: 100%;
        border: 1px solid var(--border-color);
        box-sizing: border-box;
        border-radius: 6px;
        box-shadow: none;
        padding:16px;
        flex-shrink: 0;
        border-top:6px solid var(--primary-bg-color)
    }

    .section_label {
        font-size: 14px;
        line-height: 17px;
    }

    .section_container:first-of-type {
       padding-bottom:32px;
       margin-bottom:32px;
       border-bottom:1px solid var(--border-color);
    }

    .section_container:last-of-type {
        padding-top:32px;
        margin:0;
        margin-top: 32px;
        border-top:1px solid var(--border-color);
     }

    .sub_title {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: var(--secondary-text-color);
    }

    .price {
        margin:0;
        font-size: 18px;
        line-height: 22px;
        color: var(--secondary-text-color);
    }

    .right_content {
        padding:0;
    }

    .information {
        margin-bottom:24px;
    }

    .information_title {
        font-size: 14px;
    }

    .information_text{
        font-size: 13px;
    }

    .purchase_button {
        position:relative;
        left:0;
        bottom:0;
        text-align: left;
    }
}

@media(min-width: 1024px) {
    .left_container {
        max-width:380px;
    }

    .left_content {
        width: 100%;
        padding:32px;
    }
}
