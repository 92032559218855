.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-top: 8px;
}

.input {
  flex: 1;
  width: 106px;
}

.first_input {
  margin-right: 20px;
}

.separator {
  width: 24px;
}

.buttons {
  display:flex;
  align-items: center;
  justify-content: space-between;
  margin:32px -12px 0;
}

.buttons__item {
  width: 100%;
  padding:0 12px;
}

.buttons__item > button {
  width: 100%;
}

@media(min-width: 768px) {
  .input {
    flex: 1;
    width: 209px;
  }
}

.buy_card {
    margin: 0;
    font-size: 14px;
    line-height: 17px;
    color: var(--links-color);
}

.buttons {
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin-top:32px;
}