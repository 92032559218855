.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.leftContent {
  background-color: white;
  padding: 24px 16px 40px 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.titleLeft {
  margin-bottom: 24px;
}

.mainTitleLeft {
  margin-bottom: 32px;
}

.mainTitleLeft > p {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: var(--primary-bg-color);
  margin: 0;
}

.explanationLeft {
  margin-top: 24px;
  margin-bottom: 32px;
  display: none;
}

.titleLeft {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: var(--secondary-bg-color);
  margin: 0;
}

.textLeft {
  font-size: 15px;
  line-height: 20px;
  margin-top: 4px;
  margin-bottom: 0;
  color: var( --secondary-text-color)
}

.input {
  margin-bottom: 24px;
}

.inputPassword {
  margin-bottom: 32px;
}

.connectionContainer {
  display: flex;
  flex-direction: column;
}

.forgotPassword {
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  text-decoration-line: underline;
  color: var(--links-color);
  cursor: pointer;
  margin-top: 17px;
}

.rightContent {
  background-color: white;
  padding: 24px 16px 56px 16px;
}

.mainTitleRight {
  margin-bottom: 32px;
}

.titleRight > span {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #006534;
}

.list {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subtitle {
  margin-top: 24px;
  margin-bottom: 20px;
}

.subtitle > span {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #00231B;
}

.list:not(:last-child) {
  margin-bottom: 20px;
}

.listText {
  font-size: 14px;
  line-height: 20px;
  color: var(--tertiary-text-color)
}

.subscribeButton {
  margin-top: 40px;
}

@media(min-width: 768px) {
  .main {
    flex-direction: row;
  }

  .leftContent {
    max-width: 408px;
    border-top-left-radius: 50px;
    padding: 40px 56px 34px 56px;
    box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.2);
  }

  .explanationLeft {
    display: block;
  }

  .connectionContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .connectButtonContainer {
    min-width: 194px;
  }

  .rightContent {
    width: 246px;
    padding: 40px 40px 74px 40px;
  }

  .mainTitleRight {
    margin-bottom: 24px;
  }

  .subscribeButton {
    margin-top: 55px;
  }
}

.leftContent {
    background-color: none;
    padding: 25px 16px 40px 16px;
    box-shadow: none;
    z-index: 2;
    margin:0 auto;
}

.mainTitleLeft {
    margin-bottom: 16px;
}

.mainTitleLeft > p {
    font-family: 'Titillium Web', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 37px;
    color: var(--primary-text-color);
    margin: 0;
}

.explanationLeft {
    display: block;
    margin:0 0 32px;
}

.connectionContainer {
    padding-bottom:40px;
    border-bottom:1px solid #E6E7E9;
}

.forgotPassword {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-decoration-line: none;
    color: var(--links-color);
    cursor: pointer;
    margin-top: 24px;
    display: none; /* @TODO: Show modal when LCG V2 website is online */
}

.connectButtonContainer {
    min-width: 194px;
}

.connectButtonContainer > button {
    width:100%;
}

.rightContent {
    background-color: white;
    padding: 0 16px 68px 16px;
    width:auto;
    margin:0 auto;
}

.titleRight {
    margin-bottom: 32px;
}

.titleRight > span {
    font-family: 'Titillium Web', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 37px;
    color: var(--primary-text-color);
    margin: 0;
}

.subtitle {
    margin-top: 0;
    margin-bottom: 16px;
}

.subtitle > span {
    font-weight: 500;
    font-size: 15px;
    line-height: 160%;
    color: var(--primary-text-color);
}

.textLeft {
    font-size: 15px;
    line-height: 160%;
}

.subscribeButton {
    margin-top: 32px;
}

.subscribeButton  button {
    width:100%;
}

@media only screen and (min-width: 500px) {
    .connectButtonContainer > button {
        width:auto;
    }

    .subscribeButton  button {
        width:auto;
    }

    .connectionContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items:center;
    }

    .forgotPassword {
        margin-top:0;
    }
}

@media only screen and (min-width: 768px) {
    .leftContent {
        margin: 0;
        padding: 0 32px;
        max-width: 519px;
        border-right:1px solid #E6E7E9;
    }

    .mainTitleLeft > p {
        font-size: 32px;
        line-height: 49px;
    }

    .connectionContainer {
        border-bottom:none;
        padding: 0;
    }

    .rightContent {
        margin: 0;
        padding: 0 32px;
        max-width: 374px;
    }
}

